
/* *{
  font-family: 'Roboto', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
} */
@media (min-width: 1360px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1240px;
  }
  }
  
  html {
    scroll-padding-top: 35px; /* height of sticky header */
  }
  body{
    background-color:#EEEFF2;
  }
  .profile-d{
    width: 35px;
      height: 35px;
      display: inline-block;
      background: #47b6e3;
      padding: 4px 6px;
      text-align: center;
      border-radius: 50%;
      color: #fff;
      font-weight: 600;
    cursor:pointer;
  }
  .black-sm-pannel{
    background-color: #424347;
    height: 100vh;
    width:100%;
  }
  .menu-anchor{
    display: block;
    text-align: center;
    padding: 10px 0;
  }
  .menu-anchor:hover{
    opacity: 0.6;
  }
  .dummy-imgsection{
    background-color: #DFE2ED;
    height: 100vh;
    padding: 20px 60px;
  }
  .sm-thumb{
    margin-bottom: 20px;
    cursor: pointer;
  }
  .plus-size{
    font-size: 20px;
  }
  .right-blacksection{
    background-color:#424347;
    height: 100vh;
    width: 100%;
  }
  .middle-section{
    background-color: #C7CAD4;
    height: 100vh;
  }
  .middle-sectionhead{
    background-color: #EEEFF2;
    padding: 10px;
  }
  .middle-footer {
    padding-top: 20px;
    font-weight: 600;
  }
  .midle-sec-img{
    padding: 30px;
  }
  .middle-headbutton{
    background-color: #D2D6DB;
    border-radius: 5px;
    border:none;
    margin-right: 10px;
  }
  .middle-sectionhead{
    padding-left: 30px;
  }
  .srch-box {
    padding: 40px 40px 0 40px;
  }
  .srch-box p{
    color: #fff;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }
  .srch-box input{
    background-color: transparent;
    color:#fff
  }
  .srch-box input::placeholder{
    color:#fff
  }
  .box-img-div{
    padding: 0 40px 0 40px;
  }
  .box-img-div ul{
    padding-left: 0;
  }
  .box-img-div ul li{
    list-style: none;
    display: inline-block;
    width: 21%;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .box-img-div ul li img{
    width:100%;
  }
  .p-40px{
    padding: 40px;
  }
  .nav-item {
    padding-right: 20px;
  }
.template-list-container {
  background-color: #f0f4f8;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow-y: auto;
}

.template-header {
  text-align: center;
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.template-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.template-item, .new-template-item {
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.new-template-item {
  background-color: #e6f7ff;
  text-align: center;
}

.template-item:hover, .new-template-item:hover {
  background-color: #e0e0e0;
}

.template-link {
  text-decoration: none;
  cursor: pointer;
  color: #007bff;
}

.template-link:hover {
  color: #0056b3;
}

.no-templates {
  text-align: center;
  color: #999;
  font-style: italic;
}

.file-input-container {
  position: relative;
  overflow: hidden;
  margin: 20px 0; /* Adjust margin as needed */
}

.custom-file-upload {
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
  background-color: transparent; /* Background color */
  color: #333; /* Text color */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transitions */
  justify-content: center;
}

.custom-file-upload:hover {
  background-color: #e2e6ea; /* Background color on hover */
  border-color: #007bff; /* Border color on hover */
}

.custom-file-upload input[type="file"] {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #fff; /* Border style */
  border-radius: 5px; /* Rounded corners */
  background-color: #6c757d; /* Gray background */
  color: white; /* Text color */
  text-align: center; /* Center text */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transitions */
}


.custom-file-upload input[type="file"]:hover {
  background-color: #5a6268; /* Darker gray on hover */
  border-color: #495057; /* Darker border on hover */
}
.save-button {
  background-color: #007bff; /* Green color for the Save button */
}

.save-button:hover {
  background-color: #495057; /* Darker green on hover */
}

.right-panel {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-family: Arial, sans-serif;
  background-color:#424347;
    height: 100vh;
    width: 100%;
}
label.newlabel {
  font-size: 13px !important;
}

.properties-section {
  background-color: transparent;
  padding: 15px 5px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;  
}

.properties-section h4 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
}

.property-item {
  margin-bottom: 12px;
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
}

.property-item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #fff;
}

.property-item input,
.property-item select {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.property-item input[type="number"] {
  width: calc(100% - 10px);
}

.newinput{
  max-width: 85px !important;
    height: 32px;
    padding: 5px !important;
    display: flex;
    justify-content: flex-end;
}
.newselect{
  max-width: 85px !important;
    height: 32px;
    padding: 5px !important;
    display: flex;
    justify-content: flex-end;
}

.property-item button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.property-item button:hover {
  background-color: #0056b3;
}
.layers-section {
  background-color: transparent;
  padding: 15px 5px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  margin-bottom: 10px;
}

.layers-header {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
}

.layers-list {
  background-color: transparent;
  padding: 8px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 15px;
  padding: 1px 5px;
  margin: 1px;
}

.draggable-item {
  background-color: #444; /* Background for individual layer */
  padding: 6px 10px; /* Adjust padding for content */
  margin-bottom: 5px; /* Space between layers */
  border-radius: 4px; /* Rounded corners for individual layers */
  color: #f5f5f5; /* Text color */
  display: flex; /* Flexbox for better alignment */
  justify-content: space-between; /* Align text/icons properly */
  align-items: center; /* Vertically center content */
  cursor: move; /* Indicate draggable */
}

.draggable-item:hover {
  background-color: #555; /* Hover effect for layers */
}

.no-layers-message {
  color: #888; /* Lighter color for no layers message */
  font-style: italic; /* Italic for emphasis */
  text-align: center; /* Center text */
}
.selected-class{
  background-color: #fff !important;
  color: black !important;
}
.newselect_Animate{
  max-width: 140px !important;
    height: 35px;
    padding: 5px !important;
    display: flex;
    justify-content: flex-end;
}
.canvas-container{
  background-color: #fff !important;
}